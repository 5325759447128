<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-file-download
        </v-icon> Download BannerEx
      </h2>
      <br>
      <v-form>
        <v-card-text class="text-body-1">
          <v-container fluid>
            <font
              class="my-p-style"
            >
              Please select your operating system to proceed download
            </font>
            <br>
            <v-radio-group
              v-model="operatingSystem"
              column
              class="mt-10"
            >
              <v-radio
                label="Windows"
                value="windows"
              />
              <v-spacer />
              <v-radio
                label="Linux"
                value="linux"
              />
              <v-spacer />
              <v-radio
                label="Mac"
                value="mac"
              />
            </v-radio-group>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            :disabled="!isOperatingSystemSelected"
            @click.prevent="downloadBannerEx()"
          >
            {{ btnText }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'DownloadBannerEx',

  data () {
    return {
      showModal: true,
      operatingSystem: '',
      btnText: 'Download',
    };
  },
  computed: {
    isOperatingSystemSelected () {
      return this.operatingSystem !== '';
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  methods: {
    downloadBannerEx () {
      this.btnText = 'Downloading...';
      this.$store.dispatch('bannerex/getLatestBuildRecord', this.operatingSystem)
      .then(res => {
        const buildRecord = res.data;
        this.$store.dispatch('bannerex/downloadBannerEx', {
          recordId: buildRecord.id,
          recordFileName: buildRecord.file_name,
        });
      });
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
    font-size: 16px;
    font-weight: bold;
    color: #546E7A;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
</style>
