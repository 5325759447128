<template>
  <section>
    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-card
        shaped
        color="#ECEFF1"
      >
        <br>
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-monitor-edit
          </v-icon> Edit Device
        </h2>
        <v-card-text>
          <v-form
            ref="form"
            v-model="isFormValid"
          >
            <v-container>
              <v-row class="text-center">
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="deviceId"
                    label="Device Id"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="deviceNumber"
                    label="Device Number*"
                    :rules="[(v) => !!v || 'This field is required']"
                  />
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="location"
                    label="Location*"
                    :rules="[(v) => !!v || 'This field is required']"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="deviceTimeZone"
                    menu-props="auto"
                    :items="timeZonesByName "
                    label=" Device Time-Zone*"
                    :rules="[(v) => !!v || 'This field is required']"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                >
                  <div class="my-card-style">
                    <font class="my-dimension-style">
                      Screen Dimension
                    </font>
                    <v-radio-group
                      v-model="selectedScreenDimension"
                      row
                      align-content="center"
                      justify="center"
                      class="my-radio-btn-style"
                      @change="changeScreenDimension()"
                    >
                      <v-radio
                        label="Landscape "
                        value="landscape"
                      />
                      <v-radio
                        label="Portrait"
                        value="portrait"
                      />
                    </v-radio-group>
                    <v-row class="my-dimension-row-style">
                      <div class="my-div-style">
                        <v-text-field
                          v-model="height"
                          label="Height"
                          outlined
                          dense
                          type="number"
                          class="my-dimension-field-style"
                        />
                        <font class="my-font-style">
                          X
                        </font>
                        <v-text-field
                          v-model="width"
                          label="Width"
                          outlined
                          type="number"
                          dense
                          class="my-dimension-field-style"
                        />
                      </div>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <br>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            @click="submit()"
          >
            update
          </v-btn>
        </v-card-actions>
      </v-card>
      <centre-spinner
        :loading="loading"
      />
    </v-dialog>
  </section>
</template>

<script>
import Constants from 'src/constants';
import global from 'src/mixins/global';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'UpdateDigiBoard',
    components: {
      'centre-spinner': spinner,
    },
    mixins: [global],
    props: {
      selectedDigiBoardId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        loading: false,
        isFormValid: false,
        rules: {
          select: [(v) => !!v || 'This field is required'],
        },
        dialog: true,
        deviceId: '',
        deviceNumber: null,
        location: '',
        deviceTimeZone: null,
        height: null,
        width: null,
        warranty: null,
        selectedScreenDimension: null,
      };
    },
    computed: {
      selecteddigiBoardDetails () {
        return this.$store.getters['digiboards/getBoardDetails'];
      },
      timeZones () {
        return this.$store.getters['userprofile/getTimeZones'];
      },
      timeZonesByName () {
        return Object.keys(this.timeZones).map((key) => ({ text: key, value: this.timeZones[key] }));
      },
    },
    watch: {
      dialog: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
        await this.fetchSelectedBoardDetails();
        this.fillData();
        this.getAllTimeZone();
    },
    methods: {
      fillData () {
          this.deviceId = this.selecteddigiBoardDetails.external_id;
          this.deviceNumber = this.selecteddigiBoardDetails.board_number;
          this.location = this.selecteddigiBoardDetails.location;
          this.deviceTimeZone = this.selecteddigiBoardDetails.timezone;
          this.warranty = this.selecteddigiBoardDetails.under_warranty;
          this.height = this.selecteddigiBoardDetails.height;
          this.width = this.selecteddigiBoardDetails.width;
      },
      async fetchSelectedBoardDetails () {
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchBoardDetails', {
          digiBoardId: this.selectedDigiBoardId,
        });
        this.loading = false;
      },
      async submit () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('digiboards/updateDigiBoardDetails', {
          editedBoardDetails: {
            board_number: this.deviceNumber,
            location: this.location,
            timezone: this.deviceTimeZone,
            warranty: this.warranty,
            height: this.height,
            width: this.width,
           },
          id: this.selectedDigiBoardId,
          }).then(response => {
          this.$store.dispatch('alert/onAlert', {
           message: 'Device updated successfully.',
           type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('fetch-digiboard-list');
          this.dialog = false;
          }).catch(() => {
           this.loading = false;
          });
        },
      getAllTimeZone () {
        this.$store.dispatch('userprofile/fetchTimeZones');
      },
      changeScreenDimension () {
         if (this.selectedScreenDimension === 'portrait') {
          this.height = '1920';
          this.width = '1080';
        } else {
          this.height = '1080';
          this.width = '1920';
        }
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-font-style {
  padding-top: 4px;
  margin: 4px;
  height: 40px;
}
.my-dimension-row-style {
  padding-left: 25px;
  margin-top: 2px;
}
.my-dimension-field-style {
  width: 90px;
}
.my-div-style {
  display: flex;
}
.my-dimension-style {
  font-weight: bold;
  margin-left: 35px;
}
.my-radio-btn-style {
  margin-top: 10px !important;
  margin-left: 10px;
}
.my-card-style {
  border-left: 5px solid #FF3700 !important;
}
</style>
