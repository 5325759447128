<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-outline-card
          title="Total Licenses"
          status-icon="mdi-license"
          icon-color="#00695C"
          :value="String(digiBoardLicenceCounts.total_licenses)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-outline-card
          title="Licenses Used"
          status-icon="mdi-clipboard-edit"
          icon-color="#01579B"
          :value="String(digiBoardLicenceCounts.used_licenses)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-outline-card
          title="Active Licenses"
          status-icon="mdi-check-bold"
          icon-color="#E65100"
          :value="String(digiBoardLicenceCounts.active_licenses)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-outline-card
          title="Expired Licenses"
          status-icon="mdi-close-network"
          icon-color="#C62828"
          :value="String(digiBoardLicenceCounts.expired_licenses)"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import global from 'src/mixins/global';
  export default {

    name: 'DigiBoardLicenceCounts',
    mixins: [global],

    computed: {
      digiBoardLicenceCounts () {
        return this.$store.getters['digiboards/getDigiBoardLicenceCounts'] ?? [];
      },
    },
    async mounted () {
      this.permissionCheck('display-licenses') && await this.getDigiBoardLicenceCounts();
    },
    methods: {
      getDigiBoardLicenceCounts () {
        this.$store.dispatch('digiboards/fetchDigiBoardLicenceCounts');
      },
    },
  };
</script>
