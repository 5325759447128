<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <download-banner-ex
      v-if="showDownload"
      @closed="showDownload = false"
    />
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-television
      </v-icon> Device List
    </h2>
    <digiboard-licence-counts v-if="permissionCheck('display-licenses')" />
    <v-alert
      v-if="!permissionCheck('read-board-list')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-col
        cols="12"
        lg="12"
        class="text-right"
      >
        <v-btn
          color="#455A64"
          @click="showBannerExDownloadModal()"
        >
          <v-icon
            dark
            left
          >
            mdi-download
          </v-icon>
          BannerEx
        </v-btn>
        <v-btn
          color="#FF3700"
          @click="createDeviceStructure()"
        >
          Group Devices
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="digiBoards"
        :search="search"
        class="elevation-1"
        :loading="updateProgress"
        loader-height="3"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.external_id }}</td>
            <td>{{ item.board_number }}</td>
            <td>
              <font
                v-if="item.health_record !== null"
                :color="getHealthColor(item.health_record.status)"
                class="font-my-style"
              >
                {{ item.health_record.status | healthStatusUpdate }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>{{ item.location }}</td>
            <td>
              <font
                v-if="item.licence !== null"
                :color="getColor(item.licence.status)"
                class="font-my-style"
              >
                {{ item.licence.status | statusUpdate }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.licence !== null">
                {{ item.licence.end | convertToLocal }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.activity !== null && item.activity.event_triggered_at !== null">
                {{ item.activity.event_triggered_at | convertToLocal }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font
                v-if="item.activity !== null && item.activity.status !== null"
                :color="boardStatusColor(item.activity.status)"
                class="font-my-style"
              >
                {{ item.activity.status | boardStatusUpdate }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.activity !== null && item.activity.board_updated_at !== null">
                {{ item.activity.board_updated_at | diffHumans }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="editDigiBoard(item.external_id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="digiBoardDetails(item.external_id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
      <centre-spinner
        :loading="loading"
      />
      <edit-digi-board
        v-if="showEdit"
        :selected-digi-board-id="selectedDigiBoardId"
        @closed="showEdit=false"
        @fetch-digiboard-list="fetchDigiBoardDetails()"
      />
    </v-card>
  </v-container>
</template>
<script>
  import moment from 'moment';
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import DownloadBannerEx from 'src/views/dashboard/BannerExDownload/DownloadBannerEx';
  import global from 'src/mixins/global';
  import DigiBoardLicenceCounts from './DigiBoardLicenceCounts';
  import EditDigiBoard from './EditDigiBoard';

  export default {
    name: 'DigiBoardList',
    components: {
      'centre-spinner': spinner,
      'edit-digi-board': EditDigiBoard,
      DownloadBannerEx,
      'digiboard-licence-counts': DigiBoardLicenceCounts,
    },
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (status) {
        if (status === 'active') {
          return 'Active*';
        } else if (status === 'trial') {
          return 'Trial';
        } else { return 'Expired'; }
      },
      boardStatusUpdate (status) {
        if (status === 'published') {
          return 'Published';
        } else if (status === 'completed') {
          return 'Completed*';
        } else { return 'Pending'; }
      },
      healthStatusUpdate (status) {
        if (status === 'completed') {
          return 'Online*';
        }
        return 'Offline';
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Device Id', value: 'external_id' },
          { text: 'Device Number', value: 'board_number' },
          { text: 'Device Health', value: 'status' },
          { text: 'Location', value: 'location' },
          { text: 'Licence Status', value: 'active' },
          { text: 'Expires At', value: 'end' },
          { text: 'Last Published', value: 'event_triggered_at' },
          { text: 'Device Status', value: 'status' },
          { text: 'Last Updated', value: 'board_updated_at' },
          { text: 'Edit', value: 'edit' },
          { text: 'More', value: 'more' },
        ],
        showDownload: false,
        latestBoardActivities: {},
        updateProgress: false,
        selectedDigiBoardId: '',
        showEdit: false,
      };
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      digiBoards () {
        const list = this.$store.getters['digiboards/getDigiBoards'];
        const sortedList = list.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        return sortedList;
      },
      getDownloadProgress () {
        return this.$store.getters['bannerex/getDownloadProgress'];
      },
      boardActivities () {
        return this.$store.getters['digiboards/getBoardActivity'];
      },
      boardActivityLastUpdatedAt () {
        return this.$store.getters['digiboards/getActivityLastUpdatedAt'];
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      getDownloadProgress (val) {
        if (val > 0) {
          this.showDownload = false;
        }
      },
      boardActivityLastUpdatedAt (val) {
        this.updateProgress = true;
        setTimeout(() => {
          this.permissionCheck('read-board-list') && this.latestBoardActivity();
          this.permissionCheck('read-board-list') && this.mergedDigiboardList();
        }, 3000);
      },
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-board-list') && await this.fetchDigiBoardDetails();
      this.loading = false;
    },
    methods: {
      async fetchDigiBoardDetails () {
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchDigiBoards');
        this.loading = false;
      },
      getColor (status) {
        if (status === 'active') {
          return '#2E7D32';
        } else if (status === 'trial') {
          return '#EF6C00';
        } else { return '#C62828'; }
      },
      deleteDigiBoard () {
        this.$store.dispatch('alert/onAlert', {
          message: 'You are not authorized to delete the digi-boards from the list.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      },
      createDeviceStructure () {
        if (this.permissionCheck('read-group') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.$router.push({ name: 'Device Structure' });
      },
      showBannerExDownloadModal () {
        if (this.permissionCheck('download-bannerex-build-file') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showDownload = true;
      },
      boardStatusColor (status) {
        if (status === 'published') {
          return '#E65100';
        } else if (status === 'completed') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      mergedDigiboardList () {
        for (var digiBoard of this.digiBoards) {
          if (digiBoard.external_id in this.latestBoardActivities) {
            digiBoard.activity = this.latestBoardActivities[digiBoard.external_id];
          }
        }
        this.updateProgress = false;
      },
      latestBoardActivity () {
        const boardActivities = {};
        for (const activity in this.boardActivities) {
          boardActivities[activity] = this.boardActivities[activity][0];
        }
        this.latestBoardActivities = boardActivities;
      },
      digiBoardDetails (externalId) {
        if (this.permissionCheck('read-board') === false && this.permissionCheck('read-board-update-records-list') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.$router.push(`/digi-board/${externalId}`);
      },
      getHealthColor (healthStatus) {
        if (healthStatus === 'completed') {
          return '#2E7D32';
        };
        return '#C62828';
      },
      editDigiBoard (digiBoardId) {
        if (!this.permissionCheck('edit-digi-board')) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.selectedDigiBoardId = digiBoardId;
        this.showEdit = true;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
